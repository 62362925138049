

















import { MenuInfoItem } from '@/utils/menus'
import { computed, defineComponent, PropType, ref, Ref, watch } from '@vue/composition-api'

export default defineComponent({
    props: {
        childs: {
            type: Array as PropType<MenuInfoItem[]>,
            default: () => {
                return []
            }
        }
    },
    setup(props, { root }) {
        const childActive = computed(() => root.$route.name)
        const projectUuId = computed(() => root.$route.params.projectUuId)

        const isShowChild = ref(true)
        watch(
            () => props.childs,
            () => {
                if (props.childs.length > 0) {
                    setTimeout(() => {
                        isShowChild.value = true
                    }, 500)
                } else {
                    isShowChild.value = false
                }
            }
        )

        const goPath = (item: MenuInfoItem) => {
            if (item.routeName == root.$route.name) {
                return
            }
            const pathInfo: any = {
                name: item.routeName
            }
            if (projectUuId.value) {
                pathInfo.params = {
                    projectUuId: projectUuId.value
                }
            }
            root.$router.push(pathInfo)
        }

        const handleMenuDisabled = (item: MenuInfoItem) => {
            if (item.routeName == 'ProjectIntroduce') {
                return false
            }
            if (item.disabled) {
                return true
            }
            return !Boolean(projectUuId.value)
        }
        return {
            childActive,
            goPath,
            isShowChild,
            handleMenuDisabled
        }
    }
})
