













import { defineComponent, ref } from '@vue/composition-api'
import { Container, Aside, Main } from 'element-ui'
import ProjectLayoutAside from './components/ProjectLayoutAside.vue'

export default defineComponent({
    components: {
        [Container.name]: Container,
        [Aside.name]: Aside,
        [Main.name]: Main,
        ProjectLayoutAside
    },
    setup() {
        return {}
    }
})
