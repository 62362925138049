





































import { defineComponent, ref, computed, watch, Ref, ComputedRef } from '@vue/composition-api'
import { projectMenu, MenuInfoItem } from '@/utils/menus'
import Logo from '@/components/Logo.vue'
import ProjectLayoutAsideChild from './ProjectLayoutAsideChild.vue'
import { Dropdown, DropdownItem, DropdownMenu, Tooltip } from 'element-ui'
import { FAQ_URL,FAQ_ENGLISH_URL, OPERATIONAL_MANUAL_URL, OPERATIONAL_MANUAL_URL_ENGLISH } from '@/config/constant'
import LocaleSelect from '@/components/LocaleSelect.vue'

export default defineComponent({
    components: {
        [Tooltip.name]: Tooltip,
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        Logo,
        ProjectLayoutAsideChild,
        LocaleSelect
    },
    setup(props, { root }) {
        const menuList = ref(projectMenu)
        const activeMenu = computed(() => root.$route.name)
        const rootName = computed(() => root.$route.meta?.rootName || '')
        const projectUuId = computed(() => root.$route.params.projectUuId)

        const goPath = (item: MenuInfoItem) => {

            if (item.routeName == activeMenu.value || handleMenuDisabled(item.rootName) || item.disabled) {
                return
            }
            const pathInfo: any = {}
            if (item.rootName && item.childs!.length > 0) {
                pathInfo.name = item.childs![0].routeName
            } else {
                pathInfo.name = item.routeName
            }
            if (projectUuId.value) {
                pathInfo.params = {
                    projectUuId: projectUuId.value
                }
            }
            root.$router.push(pathInfo)
        }

        const childAry: ComputedRef<MenuInfoItem[]> = computed(() => {
            return menuList.value.find((v: MenuInfoItem) => v.rootName == rootName.value)?.childs || []
        })

      

        const handleMenuDisabled = (rootName?: string) => {
            if (rootName == 'detail') {
                return false
            }
            return !Boolean(projectUuId.value)
        }

        return {
            menuList,
            activeMenu,
            rootName,
            childAry,
            goPath,
            handleMenuDisabled,
            FAQ_URL,
            FAQ_ENGLISH_URL,
            OPERATIONAL_MANUAL_URL,
            OPERATIONAL_MANUAL_URL_ENGLISH
        }
    }
})
